<template>
  <div>
    <!--Header -->
    <div class="md:flex md:items-center md:justify-between bg-white rounded-md border py-3 px-4">
      <div class="flex-1 min-w-0">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>Email Templates</el-breadcrumb-item>
          <el-breadcrumb-item>Create</el-breadcrumb-item>
        </el-breadcrumb>

        <input v-model="name" class="border-b-2 border-gray-400 p-1 text-lg mt-2 focus:outline-none focus:border-primary-700" placeholder="you@example.com" />
      </div>
      <div class="mt-1 flex">
        <el-button type="primary"> Save Changes</el-button>
        <el-button type="danger"> Delete</el-button>
      </div>
    </div>

    <!-- Drag & Drop Editor -->
    <div class="mt-4 border-b border-l border-r rounded-md overflow-hidden">
      <EmailBuilder ref="emailEditor" :isJourneyCampaign="false" :showOnlyEditor="true"></EmailBuilder>
    </div>
  </div>
</template>

<script>
import EmailBuilder from '@/components/campaign/contentBuilder/email/editor/emailEditor';
import { fetchChannelProviderList } from '@/services/campaign';
export default {
  name: 'createTemplate',
  props: {},
  computed: {},
  components: {
    EmailBuilder
  },
  data() {
    return {
      previewMode: false,

      name: 'dummy template',
      content: {
        subject: 'Preview Subject Goes Here',
        fromName: 'Email Preview'
      }
    };
  },

  methods: {
    async fetchMailProviderList() {
      try {
        let params = {
          channel: 'email',
          readArchived: this.readOnly
        };
        let result = await fetchChannelProviderList(params, this);
        this.providerList = result.data.data;
        if (result.data.data.length <= 0) {
          this.warningToast('Email provider is not configured.');
        }
        return result.data.data;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something went wrong');
      }
    }
  },

  async mounted() {
    let list = await this.fetchMailProviderList();
    console.log('list is', list);

    this.$refs.emailEditor.renderCampaignContent({
      providerId: list.length > 0 ? list[0].id : null,
      email_config: {
        subject: 'Subject is here..',
        fromName: 'Brand Name'
      },
      content: null
    });
  }
};
</script>
